import styled from 'styled-components'
import { ArrowRight } from 'widgets'

const HeroBanner = () => {
  return (
    <div className="container flex flex-col gap-4">
      <div className="bg-[#00000033] border border-solid border-[#333] backdrop-blur-2xl p-5 pb-0 flex flex-col gap-9 md:flex-row md:items-center lg:px-24 xl:gap-32">
        <div className="flex flex-col gap-16">
          <div className="flex flex-col gap-2 md:gap-7">
            <p className="text-2xl tracking-[0.96px] font-righteous lg:text-3xl">
              Satoin App
              <br /> User Centric Crypto
              <br className="md:hidden" /> Network
            </p>

            <p className="text-sm md:max-w-[435px] lg:text-xl">
              Innovative marketing platform tailored for the cryptocurrency industry,{' '}
              <span className="text-main-red font-bold align-baseline">
                serving as a bridge between users, projects, investment funds, and Key Opinion Leaders.
              </span>
            </p>
          </div>
          <div className="items-center gap-3 hidden md:flex">
            <a href="https://login.satoin.com" target="_blank" rel="noreferrer">
              <button type="button" className="tw-btn self-start text-lg">
                Access App
              </button>
            </a>

            <ArrowRight className="text-2xl" />
          </div>
        </div>

        <img
          src="/images/home/iphone-14-pro-max.png"
          alt="iPhone"
          className="w-[200px] h-[322px] lg:w-[288px] lg:h-[496px] object-cover object-top drop-shadow-white self-center shrink-0 lg:mt-24"
        />
      </div>
      <a href="https://login.satoin.com" target="_blank" rel="noreferrer" className="w-full">
        <button type="button" className="tw-btn text-lg relative md:hidden w-full">
          Access App
          <div className="absolute top-0 right-0 h-full aspect-square p-[6px]">
            <div className="rounded-full border border-solid border-main-black flex justify-center items-center w-full h-full">
              <ArrowRightStyle />
            </div>
          </div>
        </button>
      </a>
    </div>
  )
}

export default HeroBanner

const ArrowRightStyle = styled(ArrowRight)`
  path {
    fill: #0b0b0b;
  }
`
