/* eslint-disable react/no-array-index-key */
import clsx from 'clsx'
import { animated, useInView, useSpring, useTrail } from '@react-spring/web'
import React, { useEffect, useState } from 'react'
import { uuid } from 'uuidv4'

interface PartnersProps {
  className?: string
}

const Partners: React.FC<PartnersProps> = ({ className }) => {
  const [ref, inView] = useInView()

  const [trails, api] = useTrail(9, () => ({
    x: 0,
    opacity: 1,
    from: { x: 50, opacity: 0 },
  }))

  useEffect(() => {
    if (inView) {
      api.start({
        x: 0,
        opacity: 1,
        from: {
          x: 50,
          opacity: 0,
        },
      })
    }
  }, [api, inView])

  return (
    <div id="partners" className={clsx(className)}>
      <div className="container xl:max-w-[1660px]">
        <div className="flex flex-col gap-1">
          <p className="text-2xl font-righteous md:text-center md:text-3xl lg:text-4xl xl:text-5xl">Partners</p>
          <p className="text-sm text-[#6C6C6C] md:text-center md:text-base lg:text-2xl xl:text-3xl">Satoin’s network</p>
        </div>

        <div ref={ref} className="grid grid-cols-3 gap-2 mt-4 md:hidden">
          {Array.from({ length: 9 }).map((_, index) => (
            <animated.div style={trails?.[index]} className="border border-solid border-[#333] backdrop-blur-md p-2">
              <img src="/images/home/partner.png" alt="partner" className="w-full object-contain" />
            </animated.div>
          ))}
        </div>

        <div className="md:flex flex-col gap-2 xl:gap-8 mt-4 lg:mt-6 xl:mt-20 relative hidden overflow-hidden">
          <div className="absolute h-full w-[100px] lg:w-[150px] xl:w-[200px] bg-gradient-to-r from-[#050505] from-30% top-0 left-0 z-10 pointer-events-none" />
          <div className="absolute h-full w-[100px] lg:w-[150px] xl:w-[200px] bg-gradient-to-l from-[#050505] from-30% top-0 right-0 z-10 pointer-events-none" />

          <AnimationLeftToRight />
          <AnimationLeftToRight isToLeft />
          <AnimationLeftToRight />
        </div>
      </div>
    </div>
  )
}

export default Partners

const AnimationLeftToRight: React.FC<{ isToLeft?: boolean }> = ({ isToLeft }) => {
  const [block, setBlock] = useState([uuid(), uuid(), uuid(), uuid()])

  return (
    <div className="flex justify-center gap-2 xl:gap-8">
      {block.map((id) => (
        <Block
          isToLeft={isToLeft}
          key={id}
          id={id}
          onRest={() => {
            // Khi animation hoàn tất, cập nhật trạng thái blocks
            setBlock((prev) => [uuid(), uuid(), uuid(), uuid(), ...prev?.slice(0, 3)])
          }}
        />
      ))}
    </div>
  )
}

interface BlockProps {
  onRest?: () => void
  id: string
  isToLeft?: boolean
}

const Block: React.FC<BlockProps> = ({ onRest, id, isToLeft }) => {
  const spring = useSpring({
    x: isToLeft ? '-100%' : '100%',
    from: { x: isToLeft ? '100%' : '-100%' },
    onRest,
    config: {
      duration: 70000,
    },
  })

  return (
    <animated.div key={id} style={spring} className="w flex items-center gap-2 xl:gap-8">
      {Array.from({ length: 5 }).map((_, index) => (
        <div
          key={`${id}_${index}`}
          className="border border-solid border-[#333] backdrop-blur-md p-2 w-[200px] h-[65px]"
        >
          <img src="/images/home/partner.png" alt="partner" className="w-full object-contain max-h-[49px]" />
        </div>
      ))}
    </animated.div>
  )
}
