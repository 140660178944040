import React from 'react'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 17 16" fill="none">
      <path d="M8.5 1.3335L8.5 14.6668" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M1.8335 8L15.1668 8" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  )
}

export default Icon
