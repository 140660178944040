import { useInView, animated } from '@react-spring/web'
import clsx from 'clsx'
import { AcademyIcon, AirDropIcon, BoostingIcon, KOLIcon, MessageText } from 'widgets'
import AppInfoMobileView, { IAppInfoItem } from './AppInfoMobileView'
import AppInfoDesktopView from './AppInfoDesktopView'

interface AppInfoProps {
  className?: string
}

const DATA: IAppInfoItem[] = [
  {
    key: 'actions',
    icon: <MessageText className="" />,
    title: 'ACTIONS',
    description:
      'Users can complete tasks like watching videos, joining groups, or following social media accounts to earn points and tokens. Allowing users to improve their rank and status within the community.',
  },
  {
    key: 'airdrop',
    icon: <AirDropIcon className="text-2xl" />,
    title: 'Airdrop',
    description:
      'Satoin App offers detailed airdrop info, including reward dates and guidelines, and ensures users never miss reward opportunities from KOLs and crypto projects.',
  },
  {
    key: 'kol-system',
    icon: <KOLIcon className="text-2xl" />,
    title: 'KOL System',
    description:
      'KOL system connects users with crypto experts, offering AMA sessions and social media updates to stay informed.',
  },
  {
    key: 'academy',
    icon: <AcademyIcon className="text-2xl" />,
    title: 'Academy',
    description:
      'Satoin Academy offers articles and courses on cryptocurrency, with interactive discussions to deepen market knowledge.',
  },
  {
    key: 'boosting',
    icon: <BoostingIcon className="text-2xl" />,
    title: 'Boosting',
    description:
      'Satoin App supports projects by providing listing services and marketing solutions to boost visibility through ads, PR, and events.',
  },
]

const AppInfo: React.FC<AppInfoProps> = ({ className }) => {
  const [ref, inView] = useInView(() => ({
    to: { opacity: 1, y: 0 },
    from: {
      opacity: 0,
      y: 100,
    },
  }))

  return (
    <div id="features" className={clsx(className)}>
      <div className="container">
        <div className="flex flex-col gap-1">
          <p className="text-2xl font-righteous md:text-center md:text-3xl lg:text-4xl xl:text-5xl">Satoin App</p>
          <p className="text-sm text-[#6C6C6C] md:text-center md:text-base lg:text-2xl xl:text-3xl">
            User Centric Crypto Network
          </p>
        </div>

        <AppInfoMobileView data={DATA} />

        {/* Desktop view */}

        <AppInfoDesktopView data={DATA} />

        <animated.div ref={ref} style={inView} className="w-full h-[358px] lg:h-[500px] xl:h-[700px] relative">
          <div className="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 bg-[url(/images/home/three-iphone.png)] bg-center bg-no-repeat bg-contain h-[600px] lg:h-[800px] xl:h-[1065px] aspect-[1468/1065]" />
        </animated.div>
      </div>
    </div>
  )
}

export default AppInfo
