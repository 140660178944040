import { useInView, useTrail, animated } from '@react-spring/web'
import clsx from 'clsx'
import { useEffect } from 'react'

import { IAppInfoItem } from './AppInfoMobileView'

interface AppInfoDesktopViewProps {
  data: IAppInfoItem[]
}

const AppInfoDesktopView: React.FC<AppInfoDesktopViewProps> = ({ data }) => {
  const [trails, api] = useTrail(data?.length, () => ({
    opacity: 0,
    x: 100,
    from: { opacity: 0, x: 100 },
  }))

  const [ref, inView] = useInView(() => ({}))

  useEffect(() => {
    if (inView) {
      api.start({
        opacity: 1,
        x: 0,
        from: {
          opacity: 0,
          x: 100,
        },
      })
    }
  }, [inView, api])

  return (
    <div className="hidden lg:block mt-10">
      <div ref={ref} className="grid grid-cols-12 gap-4 ">
        {data.map((item, index) => (
          <animated.div
            key={item?.key}
            className={clsx(
              'p-6 w-full  border-solid border border-[#333] flex flex-col gap-4 h-full',
              index > 1 ? 'col-span-4' : 'col-span-6',
            )}
            style={{
              ...trails?.[index],
              ...(index === 0
                ? {
                    boxShadow: '0px 4px 50px 0px rgba(226, 89, 86, 0.15)',
                    borderColor: 'rgba(255, 255, 255, 0.25)',
                    background:
                      'conic-gradient(from 5deg at -1.41% 1.21%, rgba(225, 89, 85, 0.36) 9.100314751267433deg, rgba(226, 89, 86, 0.36) 54.63458597660065deg, rgba(0, 0, 0, 0.10) 179.32966232299805deg, rgba(2, 1, 1, 0.11) 181.1200475692749deg)',
                  }
                : {}),
            }}
          >
            <div
              className="w-[48px] aspect-square flex justify-center items-center rounded-3xl"
              style={{
                border: `0.5px solid rgba(201, 45, 45, 0.14)`,
                background:
                  'linear-gradient(151deg, rgba(217, 217, 217, 0.09) 10.77%, rgba(255, 255, 255, 0.00) 85.22%)',
              }}
            >
              {item?.icon}
            </div>

            <div className="flex flex-col gap-2">
              <p className="text-2xl">{item?.title}</p>
              <p className="text-sm">{item?.description}</p>
            </div>
          </animated.div>
        ))}
      </div>

      <SecondBlock />
    </div>
  )
}

export default AppInfoDesktopView

const SecondBlock = () => {
  const [secondTrails, secondApi] = useTrail(4, () => ({
    opacity: 0,
    y: 100,
    from: { opacity: 0, y: 100 },
  }))

  const [contentRef, contentInView] = useInView()

  useEffect(() => {
    if (contentInView) {
      secondApi.start({
        opacity: 1,
        y: 0,
        from: {
          opacity: 0,
          y: 100,
        },
      })
    }
  }, [contentInView, secondApi])

  return (
    <div className="mt-10 relative">
      <div ref={contentRef} className="grid grid-cols-12 border border-solid border-[#333] z-20">
        <div className="col-span-4">
          <animated.img
            style={secondTrails?.[0]}
            src="/images/home/app-info.webp"
            alt="App Info"
            className="w-full aspect-[395/340] object-cover max-w-[400px] mx-auto"
          />
        </div>
        <div className="col-span-8 flex flex-col gap-6 p-8 border-solid border-s border-[#333]">
          <animated.div style={secondTrails?.[1]}>
            <div className="flex flex-col gap-2">
              <p className="text-[#999999] text-3xl">&quot;Satoin App: Bridge to</p>
              <p className="text-4xl text-main-red">
                <span className="text-white align-bottom">Crypto</span> Insights & Rewards”
              </p>
            </div>
            <p className="text-[#999] text-base">
              Satoin App is more than a trading platform; it&apos;s a trusted hub for exploring projects and airdrops,
              enabling informed investment decisions. With features like the Mission App, users can complete engaging
              tasks—watching videos or joining groups—to earn points and tokens. The KOL Social system links users to
              top crypto experts, providing invaluable learning opportunities and direct interaction.
            </p>
          </animated.div>
        </div>

        <div className="col-span-5 flex flex-col gap-6 py-5 px-6 pe-10 border-solid border-e border-[#333] border-t">
          <animated.div style={secondTrails?.[2]}>
            <div className="flex flex-col gap-2">
              <p className="text-[#999999] text-3xl text-end">A Breakthrough Platform</p>
              <p className="text-4xl text-white text-end">Revolutionary</p>
            </div>
            <p className="text-[#999] text-base text-end">
              Satoin App offers seamless access on Telegram, Android, and iOS. Join us to unlock endless crypto
              opportunities and secure your financial future!
            </p>
          </animated.div>
        </div>
        <div className="col-span-7 border-solid border-[#333] border-t py-5 px-4">
          <animated.ul style={secondTrails?.[3]} className="list-outside ps-4 text-base leading-7">
            <li>
              Decentralized Finance (DeFi):{' '}
              <span className="text-[#999]">
                Satoin App makes it easy for users to participate and maximize returns.
              </span>
            </li>
            <li>
              AI and Blockchain:{' '}
              <span className="text-[#999]">
                Satoin App integrates AI to enhance user experience, support data analysis, and enable more accurate
                investment decisions.
              </span>
            </li>
            <li>
              Blockchain Growth:{' '}
              <span className="text-[#999]">
                With the crypto market projected to reach $3.3 trillion by 2024, Satoin App helps users explore and
                invest in emerging projects.
              </span>
            </li>
            <li>
              Social Interaction:{' '}
              <span className="text-[#999]">
                By integrating Telegram, Satoin App allows users to stay updated and engage in discussions efficiently.
              </span>
            </li>
          </animated.ul>
        </div>
        <div className="w-[460px] -left-[100px] aspect-square bg-[url(/images/home/line-circle.png)] bg-center bg-contain absolute bg-no-repeat z-10" />
        <div className="w-[788px] aspect-[588/672] -top-[300px] -right-[200px] bg-[url(/images/home/blur-circle.webp)] bg-center bg-contain absolute bg-no-repeat z-10" />
      </div>
    </div>
  )
}
