import { useInView, useTrail, animated, useSpring } from '@react-spring/web'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { MessageText, MinusIconOutline, PlusIconOutline } from 'widgets'
import useMeasure from 'react-use-measure'

export type IAppInfoItem = {
  key: string
  icon: JSX.Element
  title: string
  description: string
}
interface AppInfoMobileViewProps {
  data: IAppInfoItem[]
}

const AppInfoMobileView: React.FC<AppInfoMobileViewProps> = ({ data }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const [trails, api] = useTrail(data?.length, () => ({
    opacity: 0,
    x: 100,
    from: { opacity: 0, x: 100 },
  }))

  const [ref, inView] = useInView(() => ({}))
  const [contentRef, contentInView] = useInView(() => ({
    from: { opacity: 0, y: 100 },
    to: { opacity: 1, y: 0 },
  }))

  const [shortRef, { height: shortHeight }] = useMeasure()
  const [longRef, { height: longHeight }] = useMeasure()

  const props = useSpring({
    height: isExpanded ? longHeight : shortHeight,
  })

  useEffect(() => {
    if (inView) {
      api.start({
        opacity: 1,
        x: 0,
        from: {
          opacity: 0,
          x: 100,
        },
      })
    }
  }, [inView, api])

  return (
    <div ref={ref} className="lg:hidden">
      <animated.div
        className="p-6 w-full border border-solid flex flex-col gap-4 mt-4"
        style={{
          ...trails?.[0],
          boxShadow: '0px 4px 50px 0px rgba(226, 89, 86, 0.15)',
          borderColor: 'rgba(255, 255, 255, 0.25)',
          background:
            'conic-gradient(from 5deg at -1.41% 1.21%, rgba(225, 89, 85, 0.36) 9.100314751267433deg, rgba(226, 89, 86, 0.36) 54.63458597660065deg, rgba(0, 0, 0, 0.10) 179.32966232299805deg, rgba(2, 1, 1, 0.11) 181.1200475692749deg)',
        }}
      >
        <div
          className="w-[48px] aspect-square flex justify-center items-center rounded-3xl"
          style={{
            border: `0.5px solid rgba(201, 45, 45, 0.14)`,
            background: 'linear-gradient(151deg, rgba(217, 217, 217, 0.09) 10.77%, rgba(255, 255, 255, 0.00) 85.22%)',
          }}
        >
          <MessageText className="text-2xl" />
        </div>

        <div className="flex flex-col gap-2">
          <p className="text-2xl">{data?.[0]?.title}</p>
          <p className="text-sm">{data?.[0]?.description}</p>
        </div>
      </animated.div>

      <animated.div style={props} className={clsx('border-x border-b border-solid border-[#333]')}>
        {!isExpanded && (
          <div ref={shortRef} className={clsx('grid grid-cols-4 py-3')}>
            {data.slice(1).map((item, index) => (
              <animated.div
                style={trails?.[index + 1]}
                key={item?.key}
                className="flex flex-col gap-3 items-center py-3"
              >
                <div
                  className="w-[48px] aspect-square flex justify-center items-center rounded-3xl"
                  style={{
                    border: `0.5px solid rgba(201, 45, 45, 0.14)`,
                    background:
                      'linear-gradient(151deg, rgba(217, 217, 217, 0.09) 10.77%, rgba(255, 255, 255, 0.00) 85.22%)',
                  }}
                >
                  {item?.icon}
                </div>

                <p className="text-sm">{item?.title}</p>
              </animated.div>
            ))}
          </div>
        )}

        {isExpanded && (
          <div ref={longRef}>
            {data?.slice(1).map((item, index) => (
              <animated.div
                style={trails?.[index + 1]}
                key={item?.key}
                className="p-6 w-full  border-solid border-t border-[#333] flex flex-col gap-4"
              >
                <div
                  className="w-[48px] aspect-square flex justify-center items-center rounded-3xl"
                  style={{
                    border: `0.5px solid rgba(201, 45, 45, 0.14)`,
                    background:
                      'linear-gradient(151deg, rgba(217, 217, 217, 0.09) 10.77%, rgba(255, 255, 255, 0.00) 85.22%)',
                  }}
                >
                  {item?.icon}
                </div>

                <div className="flex flex-col gap-2">
                  <p className="text-2xl">{item?.title}</p>
                  <p className="text-sm">{item?.description}</p>
                </div>
              </animated.div>
            ))}
          </div>
        )}
      </animated.div>

      <button
        type="button"
        onClick={() => setIsExpanded((prev) => !prev)}
        className="w-full border-x border-b border-solid border-[#333] flex items-center justify-center py-2 px-3 gap-6"
      >
        <p>{isExpanded ? 'Collapse' : 'Expand more'}</p>{' '}
        <div
          className="w-[32px] aspect-square flex justify-center items-center rounded-3xl"
          style={{
            border: `0.5px solid rgba(201, 45, 45, 0.14)`,
            background: 'linear-gradient(151deg, rgba(217, 217, 217, 0.09) 10.77%, rgba(255, 255, 255, 0.00) 85.22%)',
          }}
        >
          {isExpanded ? <MinusIconOutline /> : <PlusIconOutline />}
        </div>
      </button>

      <div className="border border-solid border-[#333] border-t-0">
        <img
          src="/images/home/app-info.webp"
          alt="App Info"
          className="w-full aspect-square object-cover max-w-[400px] mx-auto"
        />
        <div className="p-4 flex flex-col gap-2">
          <p className="text-[#999999] text-2xl">&quot;Satoin App: Bridge to</p>
          <p className="text-2xl text-white">
            Crypto <span className="align-middle text-[#E25956]">Insights & Rewards”</span>
          </p>
          <ul className="list-outside ps-4 text-sm leading-7">
            <li>
              Decentralized Finance (DeFi):{' '}
              <span className="text-[#999]">
                Satoin App makes it easy for users to participate and maximize returns.
              </span>
            </li>
            <li>
              AI and Blockchain:{' '}
              <span className="text-[#999]">
                Satoin App integrates AI to enhance user experience, support data analysis, and enable more accurate
                investment decisions.
              </span>
            </li>
            <li>
              Blockchain Growth:{' '}
              <span className="text-[#999]">
                With the crypto market projected to reach $3.3 trillion by 2024, Satoin App helps users explore and
                invest in emerging projects.
              </span>
            </li>
            <li>
              Social Interaction:{' '}
              <span className="text-[#999]">
                By integrating Telegram, Satoin App allows users to stay updated and engage in discussions efficiently.
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default AppInfoMobileView
