import { animated, useSpring } from '@react-spring/web'

import AppInfo from './components/AppInfo'
import HeroBanner from './components/HeroBanner'
import KeyValue from './components/KeyValue'
import Partners from './components/Partners'

const HomeView = () => {
  const props = useSpring({
    from: { opacity: 0, x: 100 },
    to: { opacity: 1, x: 0 },
  })

  return (
    <animated.div style={props}>
      <HeroBanner />
      <KeyValue className="mt-4 lg:mt-7 xl:mt-28" />
      <AppInfo className="mt-4 lg:mt-7 xl:mt-28" />
      <Partners className="mt-4 md:mt-16 lg:mt-20 xl:mt-28" />
    </animated.div>
  )
}

export default HomeView
