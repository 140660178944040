import clsx from 'clsx'
import { animated, useInView, useTrail } from '@react-spring/web'
import { useEffect } from 'react'

interface KeyValueProps {
  className?: string
}

const KeyValue: React.FC<KeyValueProps> = ({ className }) => {
  const [catRef, catInView] = useInView()
  const [coinRef, coinInView] = useInView()

  const [trails, api] = useTrail(2, () => ({}))
  const [secondTrails, secondApi] = useTrail(2, () => ({}))

  useEffect(() => {
    if (catInView) {
      api.start({
        opacity: 1,
        x: 0,
        from: { opacity: 0, x: -100 },
      })
    }
  }, [api, catInView])

  useEffect(() => {
    if (coinInView) {
      secondApi.start({
        opacity: 1,
        x: 0,
        from: { opacity: 0, x: 100 },
      })
    }
  }, [secondApi, coinInView])

  return (
    <div className={clsx(className)}>
      <div className="container">
        <div className="border border-solid border-[#333]">
          <animated.div
            ref={catRef}
            className={clsx(
              'border-solid border-b border-[#333] flex flex-col items-center py-4 px-5 gap-4',
              'md:flex-row',
              'lg:px-24',
            )}
          >
            <animated.img
              style={trails?.[0]}
              src="/images/home/Maneki-neko.webp"
              alt="Maneki-neko"
              className="w-[248px] xl:w-[360px] aspect-square object-contain"
            />
            <animated.div style={trails?.[1]} className="flex flex-col gap-2 md:gap-6">
              <p className="text-2xl md:text-4xl font-righteous">Core values</p>
              <p className="text-sm text-[#999999] md:hidden">
                Satoin App is driven by Innovation, Community, Transparency, Empowerment, and Rewarding Engagement.{' '}
              </p>
              <p className="text-sm md:text-base text-[#999999] hidden md:block">
                Satoin App is driven by Innovation, Community, Transparency, Empowerment, and Rewarding Engagement. It
                connects users with projects and experts, provides trustworthy information, and empowers users with
                tools and rewards, fostering growth.
              </p>
            </animated.div>
          </animated.div>
          <animated.div
            ref={coinRef}
            className={clsx('flex flex-col items-center py-4 px-5 gap-4', 'md:flex-row-reverse', 'lg:px-24')}
          >
            <animated.img
              style={secondTrails?.[0]}
              src="/images/home/stack_of_coin.webp"
              alt="stack_of_coin"
              className="w-[248px] xl:w-[360px] aspect-square object-contain"
            />
            <animated.div style={secondTrails?.[1]} className="flex flex-col gap-2 md:items-end md:gap-6">
              <p className="text-2xl md:text-4xl font-righteous md:text-end">Key point</p>
              <p className="text-sm text-[#999999] md:hidden">
                Combining information, interaction, and reward opportunities in a single platform. Explore projects,
                connect with experts, & earn rewards seamlessly{' '}
              </p>
              <p className="text-sm md:text-base text-[#999999] hidden md:block md:text-end">
                Satoin App stands out by combining information, interaction, and reward opportunities in a single
                platform, creating an all-in-one hub for users to explore projects, connect with experts, and earn
                rewards seamlessly in the cryptocurrency world!
              </p>
            </animated.div>
          </animated.div>
        </div>
      </div>
    </div>
  )
}

export default KeyValue
